import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueCookies from "vue-cookies";

const app = createApp(App);

// 引入element-plus
import ElementPlus from "element-plus";
// import "element-plus/dist/index.css";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
app.use(ElementPlus, {
  locale: zhCn
});

import * as ElementPlusIconsVue from "@element-plus/icons-vue";
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
// import "./assets/styles/element/index.scss";
import "element-plus/theme-chalk/display.css"; // 引入响应式相关样式
// import "element-plus/dist/index.css";

// 引入样式文件
import "./assets/styles/index.scss";
// import "./assets/styles/reset.scss";
// import "./assets/styles/media.scss";
// import "./assets/styles/base.scss";
//引入阿里巴巴字体图标库
// import "./assets/styles/iconfont.scss"; //此文件只在开发环境下引用
// import "./assets/style/font/iconfont.scss";//此文件在线上环境下引用，

//自定义指令
import defineDirective from "@/utils/directives";

//路由守卫
const whiteList = [
  "/",
  "/index",
  "/case",
  "/pricing",
  "/pay",
  "/login",
  "/register",
  "/findPassword",
  "/privacy"
];
router.beforeEach(async (to, from, next) => {
  // console.log("router", to, from, next);
  const token = VueCookies.get("tokenWeb");
  const userInfo = VueCookies.get("userInfo");
  if (token && userInfo) {
    if (to.path === "/login") {
      next("/index");
    } else {
      next();
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next("/login");
    }
  }
});
// 跳转后返回顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

app.use(store).use(router).mount("#app");
