<template>
  <div id="nav"></div>
  <router-view />
</template>
<script setup>
import { loadIframe } from "@/utils/loadIframe";
const clientType = "ANALYZER";

//线上
const targetUrl = "https://wx.ringdata.com/project/";
let loginUrl =
  "https://www.ringdata.com/user-center/login/" +
  clientType +
  "/" +
  encodeURIComponent(targetUrl);
loadIframe(loginUrl);
</script>
<style lang="scss">
#app {
  font-size: 14px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #101010;
}
</style>
