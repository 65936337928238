import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import MODULE from "../constant/router/module-name";
const routes = [
  // {
  //   path: "/hello",
  //   component: () => import("@/components/HelloWorld")
  // },
  // 首页
  {
    path: "/",
    name: "WebsiteIndex",
    component: () =>
      import(/* webpackChunkName: "index" */ "../views/Index.vue")
  },
  {
    path: "/index",
    name: "Website",
    component: () =>
      import(/* webpackChunkName: "index" */ "../views/Index.vue")
  },
  // 咨询与购买
  {
    path: "/pricing",
    name: "Pricing",
    component: () =>
      import(/* webpackChunkName: "textLibrary" */ "../views/Pricing.vue")
  },
  //案例
  {
    path: "/case",
    name: "Case",
    component: () => import(/* webpackChunkName: "case" */ "../views/Case.vue")
  },
  {
    path: "/",
    redirect: "/login",
    component: () =>
      import(
        /*webpackChunkName: "Layout" */ "@/components/layout/BaseLayoutWithoutLogin"
      ),

    children: [
      /**登录 */
      {
        path: "/login",
        name: "Login",
        component: () =>
          import(/* webpackChunkName: "login" */ "../views/Login.vue"),
        meta: {}
      },
      {
        path: "/register",
        name: "Register",
        component: () =>
          import(/* webpackChunkName: "login" */ "../views/Register.vue"),
        meta: {}
      },
      {
        path: "/findpassword",
        name: "Findpassword",
        component: () =>
          import(/* webpackChunkName: "login" */ "../views/FindPassword.vue"),
        meta: {}
      }
    ]
  },

  {
    path: "/",
    redirect: "/home",
    component: () =>
      import(/*webpackChunkName: "Layout" */ "@/components/layout/BaseLayout"),
    children: [
      /* 首页模块 */
      {
        path: "/home",
        name: "HomeIndex",
        component: () =>
          import(/*webpackChunkName: "Home" */ "@/views/home/Index"),
        meta: {
          module: MODULE.HOME
          // ifHidePadding: true,
          // ifHideFooter: true
        }
      },
      /**用户权益 */
      {
        path: "/user/equity",
        name: "UserEquity",
        component: () =>
          import(/* webpackChunkName: "login" */ "../views/user/UserEquity.vue")
      },
      /* 文本库模块 */
      {
        path: "/text-library/:folderId",
        name: "TextLibraryIndexWithFolder",
        component: () =>
          import(
            /*webpackChunkName: "TextLibrary" */ "@/views/textLibrary/Index"
          ),
        meta: {
          module: MODULE.TEXT_LIBRARY
        }
      },
      {
        path: "/text-library",
        name: "TextLibraryIndex",
        component: () =>
          import(
            /*webpackChunkName: "TextLibrary" */ "@/views/textLibrary/Index"
          ),
        meta: {
          module: MODULE.TEXT_LIBRARY
        }
      },
      {
        path: "/data-record/:textLibraryId",
        name: "DataRecordIndex",
        component: () =>
          import(
            /*webpackChunkName: "TextLibrary" */ "@/views/textLibrary/DataRecord"
          ),
        meta: {
          module: MODULE.TEXT_LIBRARY
        }
      },
      {
        path: "/variable-manage/:textLibraryId",
        name: "VariableManageIndex",
        component: () =>
          import(
            /*webpackChunkName: "TextLibrary" */ "@/views/textLibrary/VariableManage"
          ),
        meta: {
          module: MODULE.TEXT_LIBRARY
        }
      },
      {
        path: "/quick-statistics/:textLibraryId",
        name: "QuickStatisticsIndex",
        component: () =>
          import(
            /*webpackChunkName: "TextLibrary" */ "@/views/textLibrary/QuickStatistics"
          ),
        meta: {
          module: MODULE.TEXT_LIBRARY
        }
      },
      {
        path: "/log/:textLibraryId",
        name: "Log",
        component: () =>
          import(
            /*webpackChunkName: "TextLibrary" */ "@/views/textLibrary/Log"
          ),
        meta: {
          module: MODULE.TEXT_LIBRARY
        }
      },
      /* 分析模块 */
      {
        path: "/analysis/:folderId",
        name: "AnalysisIndexWithFolder",
        component: () =>
          import(/*webpackChunkName: "analysis" */ "@/views/analysis/Index"),
        meta: {
          module: MODULE.ANALYSIS
        }
      },
      {
        path: "/analysis",
        name: "AnalysisIndex",
        component: () =>
          import(/*webpackChunkName: "analysis" */ "@/views/analysis/Index"),
        meta: {
          module: MODULE.ANALYSIS
        }
      },
      {
        path: "/analysis/create",
        name: "AnalysisCreate",
        component: () =>
          import(/*webpackChunkName: "analysis" */ "@/views/analysis/Create"),
        meta: {
          module: MODULE.ANALYSIS
        }
      },
      {
        path: "/analysis/model",
        name: "AnalysisModel",
        component: () =>
          import(/*webpackChunkName: "analysis" */ "@/views/analysis/Analysis"),
        meta: {
          module: MODULE.ANALYSIS
        }
      },
      {
        path: "/analysis/result/single/:objectId",
        name: "AnalysisModelResultSingle",
        component: () =>
          import(
            /*webpackChunkName: "analysis" */ "@/views/analysis/ResultSingle"
          ),
        meta: {
          module: MODULE.ANALYSIS
        }
      },

      /* 分析配置模块 */
      {
        path: "/analysis-config",
        name: "AnalysisConfigIndex",
        component: () =>
          import(
            /*webpackChunkName: "analysisConfig" */ "@/views/analysisConfig/Index"
          ),
        meta: {
          module: MODULE.ANALYSIS_CONFIG
        }
      },
      {
        path: "word-segmentation-dictionary",
        name: "WordSegmentationDictionary",
        component: () =>
          import(
            /*webpackChunkName: "analysisConfig" */ "@/views/analysisConfig/WordSegmentationDictionary/index"
          ),
        meta: {
          module: MODULE.ANALYSIS_CONFIG
        }
      },
      {
        path: "word-segmentation-dictionary-data/:dictionaryId",
        name: "WordSegmentationDictionaryData",
        component: () =>
          import(
            /*webpackChunkName: "analysisConfig" */ "@/views/analysisConfig/WordSegmentationDictionary/WordSegmentationDictionaryData"
          ),
        meta: {
          module: MODULE.ANALYSIS_CONFIG
        }
      },
      {
        path: "/sentiment-dictionary",
        name: "SentimentDictionaryIndex",
        component: () =>
          import(
            /*webpackChunkName: "analysisConfig" */ "@/views/analysisConfig/sentimentDictionary/SentimentDictionary"
          ),
        meta: {
          module: MODULE.ANALYSIS_CONFIG
        }
      },
      {
        path: "/sentiment-dictionary-data/:dictionaryId",
        name: "SentimentDictionaryDataIndex",
        component: () =>
          import(
            /*webpackChunkName: "analysisConfig" */ "@/views/analysisConfig/sentimentDictionary/SentimentDictionaryData"
          ),
        meta: {
          module: MODULE.ANALYSIS_CONFIG
        }
      },
      {
        path: "/common-word-dictionary",
        name: "CommonWordIndex",
        component: () =>
          import(
            /*webpackChunkName: "analysisConfig" */ "@/views/analysisConfig/commonWord/Index"
          ),
        meta: {
          module: MODULE.ANALYSIS_CONFIG
        }
      },
      {
        path: "/common-word-dictionary/:dictionaryId",
        name: "CommonWordDictionaryData",
        component: () =>
          import(
            /*webpackChunkName: "analysisConfig" */ "@/views/analysisConfig/commonWord/CommonWordDictionaryData"
          ),
        meta: {
          module: MODULE.ANALYSIS_CONFIG
        }
      },
      {
        path: "/encoding-dictionary",
        name: "EncodingDictionaryIndex",
        component: () =>
          import(
            /*webpackChunkName: "analysisConfig" */ "@/views/analysisConfig/encodingDictionary/EncodingDictionary"
          ),
        meta: {
          module: MODULE.ANALYSIS_CONFIG
        }
      },
      {
        path: "/encoding-dictionary/:dictionaryId",
        name: "EncodingDictionaryDataIndex",
        component: () =>
          import(
            /*webpackChunkName: "analysisConfig" */ "@/views/analysisConfig/encodingDictionary/EncodingDictionaryData"
          ),
        meta: {
          module: MODULE.ANALYSIS_CONFIG
        }
      },
      {
        path: "/event-dictionary",
        name: "EventDictionaryIndex",
        component: () =>
          import(
            /*webpackChunkName: "analysisConfig" */ "@/views/analysisConfig/eventDictionary/EventDictionary"
          ),
        meta: {
          module: MODULE.ANALYSIS_CONFIG
        }
      },
      {
        path: "/event-dictionary/:dictionaryId",
        name: "EventDictionaryDataIndex",
        component: () =>
          import(
            /*webpackChunkName: "analysisConfig" */ "@/views/analysisConfig/eventDictionary/EventDictionaryData"
          ),
        meta: {
          module: MODULE.ANALYSIS_CONFIG
        }
      },
      {
        path: "/event-manage/edit/:eventName",
        name: "EventsEditIndex",
        component: () =>
          import(
            /*webpackChunkName: "analysisConfig" */ "@/views/analysisConfig/eventDictionary/EventsManage"
          ),
        meta: {
          module: MODULE.ANALYSIS_CONFIG
        }
      },
      {
        path: "/event-manage/add/:dictionaryId",
        name: "EventsAddIndex",
        component: () =>
          import(
            /*webpackChunkName: "analysisConfig" */ "@/views/analysisConfig/eventDictionary/EventsManage"
          ),
        meta: {
          module: MODULE.ANALYSIS_CONFIG
        }
      },
      {
        path: "/category-data-set",
        name: "CategoryDataSetIndex",
        component: () =>
          import(
            /*webpackChunkName: "analysisConfig" */ "@/views/analysisConfig/categoryDataSet/CategoryDataSet"
          ),
        meta: {
          module: MODULE.ANALYSIS_CONFIG
        }
      },
      {
        path: "/category-data-set-model/:categoryTrainDatasetObjectId",
        name: "CategoryDataSetModelIndex",
        component: () =>
          import(
            /*webpackChunkName: "analysisConfig" */ "@/views/analysisConfig/categoryDataSet/CategoryDataSetModel"
          ),
        meta: {
          module: MODULE.ANALYSIS_CONFIG
        }
      },
      {
        path: "/category-data-set-model/detail/:categoryTrainDatasetObjectId",
        name: "CategoryDataSetModelDetailIndex",
        component: () =>
          import(
            /*webpackChunkName: "analysisConfig" */ "@/views/analysisConfig/categoryDataSet/CategoryDataSetModelDetail"
          ),
        meta: {
          module: MODULE.ANALYSIS_CONFIG
        }
      },
      {
        path: "/category-data-set-text/:categoryTrainDatasetObjectId",
        name: "CategoryDataSetTextIndex",
        component: () =>
          import(
            /*webpackChunkName: "analysisConfig" */ "@/views/analysisConfig/categoryDataSet/CategoryDataSetText"
          ),
        meta: {
          module: MODULE.ANALYSIS_CONFIG
        }
      },
      /* 模板库模块 */
      {
        path: "/template-repository",
        name: "TemplateRepositoryIndex",
        component: () =>
          import(
            /*webpackChunkName: "templateRepository" */ "@/views/templateRepository/Index"
          ),
        meta: {
          module: MODULE.TEMPLATE_REPOSITORY
        }
      },
      /* 回收站模块 */
      {
        path: "/recycle-bin",
        name: "RecycleBinIndex",
        component: () =>
          import(
            /*webpackChunkName: "recycleBin" */ "@/views/recycleBin/Index"
          ),
        meta: {
          module: MODULE.RECYCLE_BIN
        }
      }
    ]
  }
];

const router = createRouter({
  // history: createWebHistory("/new/"),
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
